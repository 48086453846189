export const tipe_a_1 = [{
  id: 'sign',
  type: 'file',
  fields: 'sign_pict_url',
  parent: 'agreement_data'
}, {
  id: 'Nama_Lengkap 1018',
  type: 'text',
  fields: 'question_1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1019',
  type: 'text',
  fields: 'question_2',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1021',
  type: 'text',
  fields: 'question_3',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1020',
  type: 'text',
  fields: 'question_4',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1022',
  type: 'text',
  fields: 'question_5',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1023',
  type: 'text',
  fields: 'question_6',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1024',
  type: 'text',
  fields: 'b_question_1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1025',
  type: 'text',
  fields: 'c_question_1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1026',
  type: 'text',
  fields: 'recommendation_reason',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1027',
  type: 'text',
  fields: 'name-recommendation_other_person1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1028',
  type: 'text',
  fields: 'address-recommendation_other_person1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1029',
  type: 'text',
  fields: 'phone-recommendation_other_person1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1030',
  type: 'text',
  fields: 'name-recommendation_other_person2',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1032',
  type: 'text',
  fields: 'address-recommendation_other_person2',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1031',
  type: 'text',
  fields: 'phone-recommendation_other_person2',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1033',
  type: 'text',
  fields: 'name-recommendation_other_person3',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1035',
  type: 'text',
  fields: 'address-recommendation_other_person3',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1034',
  type: 'text',
  fields: 'phone-recommendation_other_person3',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1036',
  type: 'text',
  fields: 'filler_name',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1037',
  type: 'text',
  fields: 'filler_position',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1038',
  type: 'text',
  fields: 'filler_church',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1039',
  type: 'text',
  fields: 'filler_address',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1040',
  type: 'text',
  fields: 'filler_phone',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1041',
  type: 'text',
  fields: 'filler_email',
  parent: 'recommendation'
}, {
  id: 'Text Field 38',
  type: 'text',
  fields: 'sign_city',
  parent: 'recommendation'
}, {
  id: 'Text Field 39',
  type: 'text',
  fields: 'filler_name',
  parent: 'recommendation'
}, {
  id: 'Combo Box 1010',
  type: 'date',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'Combo Box 1011',
  type: 'month',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'Combo Box 1012',
  type: 'year',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'RB_A_Secara Tertulis',
  type: 'radio',
  fields: '2_b',
  options: [{
    value: 'Tidak dapat Menilai',
    select: 0
  }, {
    value: 'Kurang',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Baik',
    select: 3
  }, {
    value: 'Sangat Baik',
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_Secara Verbal',
  type: 'radio',
  fields: '2_a',
  options: [{
    value: 'Tidak dapat Menilai',
    select: 0
  }, {
    value: 'Kurang',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Baik',
    select: 3
  }, {
    value: 'Sangat Baik',
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_Kemandirian',
  type: 'radio',
  fields: '1_d',
  options: [{
    value: 'Tidak dapat Menilai',
    select: 0
  }, {
    value: 'Kurang',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Baik',
    select: 3
  }, {
    value: 'Sangat Baik',
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_Kemampuan berefleksi',
  type: 'radio',
  fields: '1_c',
  options: [{
    value: 'Tidak dapat Menilai',
    select: 0
  }, {
    value: 'Kurang',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Baik',
    select: 3
  }, {
    value: 'Sangat Baik',
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_Ketertatikan terhadapPenelitian',
  type: 'radio',
  fields: '1_b',
  options: [{
    value: 'Tidak dapat Menilai',
    select: 0
  }, {
    value: 'Kurang',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Baik',
    select: 3
  }, {
    value: 'Sangat Baik',
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_Pemahaman Kreativitas',
  type: 'radio',
  fields: '1_a',
  options: [{
    value: 'Tidak dapat Menilai',
    select: 0
  }, {
    value: 'Kurang',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Baik',
    select: 3
  }, {
    value: 'Sangat Baik',
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_Antusias',
  type: 'radio',
  fields: 'recommendation_radio',
  options: [{
    value: 'Keberatan',
    select: 0
  }, {
    value: 'Cukup Kuat',
    select: 1
  }, {
    value: 'Kuat',
    select: 2
  }, {
    value: 'Sangat Antusias',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_1',
  type: 'radio',
  fields: 'a_radio_1',
  options: [{
    value: 'Rapi',
    select: 0
  }, {
    value: 'Ceroboh',
    select: 1
  }, {
    value: 'Baik',
    select: 2
  }, {
    value: 'Cukup Baik',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_2',
  type: 'radio',
  fields: 'a_radio_2',
  options: [{
    value: 'Cukup Percaya Diri',
    select: 0
  }, {
    value: 'Tidak Percaya Diri',
    select: 1
  }, {
    value: 'Percaya Diri',
    select: 2
  }, {
    value: 'Kurang Percaya Diri',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_3',
  type: 'radio',
  fields: 'a_radio_3',
  options: [{
    value: 'Seimbang',
    select: 0
  }, {
    value: 'Tidak Stabil',
    select: 1
  }, {
    value: 'Kadang Tidak Seimbang',
    select: 2
  }, {
    value: 'Cukup Seimbang',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_4',
  type: 'radio',
  fields: 'a_radio_4',
  options: [{
    value: 'Disukai',
    select: 0
  }, {
    value: 'Dapat Ditoleransi',
    select: 1
  }, {
    value: 'Dicari',
    select: 2
  }, {
    value: 'Diterima',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_5',
  type: 'radio',
  fields: 'a_radio_5',
  options: [{
    value: 'Cukup Dekat',
    select: 0
  }, {
    value: 'Penuh Ketegangan',
    select: 1
  }, {
    value: 'Hangat',
    select: 2
  }, {
    value: 'Jauh',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_6',
  type: 'radio',
  fields: 'a_radio_6',
  options: [{
    value: 'Hangat',
    select: 0
  }, {
    value: 'Penuh Ketegangan',
    select: 1
  }, {
    value: 'Intim',
    select: 2
  }, {
    value: 'Cukup Hangat',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_7',
  type: 'radio',
  fields: 'a_radio_7',
  options: [{
    value: 'Biasanya Tepat',
    select: 0
  }, {
    value: 'Tidak pernah Tepat Waktu',
    select: 1
  }, {
    value: 'Selalu Tepat',
    select: 2
  }, {
    value: 'Cukup Tepat',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_8',
  type: 'radio',
  fields: 'a_radio_8',
  options: [{
    value: 'Cukup Bertanggung Jawab',
    select: 0
  }, {
    value: 'Tidak Bertanggung Jawab',
    select: 1
  }, {
    value: 'Sangat Bertanggung Jawab',
    select: 2
  }, {
    value: 'Kadang Bertanggung Jawab',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_9',
  type: 'radio',
  fields: 'a_radio_9',
  options: [{
    value: 'Bekerja dengan Baik dalam Tim',
    select: 0
  }, {
    value: 'Dicari-cari untuk Dijadikan Anggota Tim',
    select: 1
  }, {
    value: 'Suka Bekerja Sendiri',
    select: 2
  }, {
    value: 'Bekerja Cukup Baik dengan Orang Lain',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_10',
  type: 'radio',
  fields: 'a_radio_10',
  options: [{
    value: 'Cukup Rendah Hati',
    select: 0
  }, {
    value: 'Arogan/Sombong',
    select: 1
  }, {
    value: 'Sangat Rendah Hati',
    select: 2
  }, {
    value: 'Kadang Rendah Hati',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_11',
  type: 'radio',
  fields: 'a_radio_11',
  options: [{
    value: 'Cukup Peduli dan Berbelas Kasih',
    select: 0
  }, {
    value: 'Tidak Peduli',
    select: 1
  }, {
    value: 'Sangat Peduli dan Penuh Belas Kasih',
    select: 2
  }, {
    value: 'Kadang Peduli',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_12',
  type: 'radio',
  fields: 'a_radio_12',
  options: [{
    value: 'Terkadang dapat Memimpin',
    select: 0
  }, {
    value: 'Tidak Berjiwa Pemimpin',
    select: 1
  }, {
    value: 'Berkemampuan Memimpin',
    select: 2
  }, {
    value: 'Menjanjikan/Berpotensi',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_13',
  type: 'radio',
  fields: 'a_radio_13',
  options: [{
    value: 'Terkadang Kooperatif',
    select: 0
  }, {
    value: 'Melawan Atasannya',
    select: 1
  }, {
    value: 'Mendukung Atasan',
    select: 2
  }, {
    value: 'Kurang Kooperatif',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'b_checkbox_1',
  type: 'checkbox',
  fields: 'b_checkbox_1',
  parent: 'recommendation'
}, {
  id: 'Check Box 1047',
  type: 'checkbox',
  fields: 'b_checkbox_2',
  parent: 'recommendation'
}, {
  id: 'Check Box 1048',
  type: 'checkbox',
  fields: 'b_checkbox_3',
  parent: 'recommendation'
}, {
  id: 'Check Box 1049',
  type: 'checkbox',
  fields: 'b_checkbox_4',
  parent: 'recommendation'
}, {
  id: 'Check Box 1050',
  type: 'checkbox',
  fields: 'b_checkbox_5',
  parent: 'recommendation'
}, {
  id: 'Check Box 1051',
  type: 'checkbox',
  fields: 'b_checkbox_6',
  parent: 'recommendation'
}, {
  id: 'Check Box 1046',
  type: 'checkbox',
  fields: 'is_free_from_tendencies',
  parent: 'recommendation'
},]
export const tipe_a_2 = [{
  id: 'sign',
  type: 'file',
  fields: 'sign_pict_url',
  parent: 'agreement_data'
}, {
  id: 'Nama_Lengkap 1017',
  type: 'text',
  fields: 'question_1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1018',
  type: 'text',
  fields: 'question_2',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1020',
  type: 'text',
  fields: 'question_3',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1019',
  type: 'text',
  fields: 'question_4',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1021',
  type: 'text',
  fields: 'question_5',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1022',
  type: 'text',
  fields: 'question_6',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1023',
  type: 'text',
  fields: 'b_question_1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1024',
  type: 'text',
  fields: 'c_question_1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1025',
  type: 'text',
  fields: 'recommendation_reason',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1026',
  type: 'text',
  fields: 'name-recommendation_other_person1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1027',
  type: 'text',
  fields: 'address-recommendation_other_person1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1028',
  type: 'text',
  fields: 'phone-recommendation_other_person1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1029',
  type: 'text',
  fields: 'name-recommendation_other_person2',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1031',
  type: 'text',
  fields: 'address-recommendation_other_person2',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1030',
  type: 'text',
  fields: 'phone-recommendation_other_person2',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1032',
  type: 'text',
  fields: 'name-recommendation_other_person3',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1034',
  type: 'text',
  fields: 'address-recommendation_other_person3',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1033',
  type: 'text',
  fields: 'phone-recommendation_other_person3',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1035',
  type: 'text',
  fields: 'filler_name',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1036',
  type: 'text',
  fields: 'filler_position',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1037',
  type: 'text',
  fields: 'filler_church',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1038',
  type: 'text',
  fields: 'filler_address',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1039',
  type: 'text',
  fields: 'filler_phone',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1040',
  type: 'text',
  fields: 'filler_email',
  parent: 'recommendation'
}, {
  id: 'Text Field 36',
  type: 'text',
  fields: 'sign_city',
  parent: 'recommendation'
}, {
  id: 'Text Field 37',
  type: 'text',
  fields: 'filler_name',
  parent: 'recommendation'
}, {
  id: 'Combo Box 101',
  type: 'date',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'Combo Box 102',
  type: 'month',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'Combo Box 103',
  type: 'year',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'RB_A_Secara Tertulis',
  type: 'radio',
  fields: '2_b',
  options: [{
    value: 'Tidak dapat Menilai',
    select: 0
  }, {
    value: 'Kurang',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Baik',
    select: 3
  }, {
    value: 'Sangat Baik',
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_Secara Verbal',
  type: 'radio',
  fields: '2_a',
  options: [{
    value: 'Tidak dapat Menilai',
    select: 0
  }, {
    value: 'Kurang',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Baik',
    select: 3
  }, {
    value: 'Sangat Baik',
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_Kemandirian',
  type: 'radio',
  fields: '1_d',
  options: [{
    value: 'Tidak dapat Menilai',
    select: 0
  }, {
    value: 'Kurang',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Baik',
    select: 3
  }, {
    value: 'Sangat Baik',
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_Kemampuan berefleksi',
  type: 'radio',
  fields: '1_c',
  options: [{
    value: 'Tidak dapat Menilai',
    select: 0
  }, {
    value: 'Kurang',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Baik',
    select: 3
  }, {
    value: 'Sangat Baik',
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_Ketertatikan terhadapPenelitian',
  type: 'radio',
  fields: '1_b',
  options: [{
    value: 'Tidak dapat Menilai',
    select: 0
  }, {
    value: 'Kurang',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Baik',
    select: 3
  }, {
    value: 'Sangat Baik',
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_Pemahaman Kreativitas',
  type: 'radio',
  fields: '1_a',
  options: [{
    value: 'Tidak dapat Menilai',
    select: 0
  }, {
    value: 'Kurang',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Baik',
    select: 3
  }, {
    value: 'Sangat Baik',
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_Antusias',
  type: 'radio',
  fields: 'recommendation_radio',
  options: [{
    value: 'Keberatan',
    select: 0
  }, {
    value: 'Cukup Kuat',
    select: 1
  }, {
    value: 'Kuat',
    select: 2
  }, {
    value: 'Sangat Antusias',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_1',
  type: 'radio',
  fields: 'a_radio_1',
  options: [{
    value: 'Ceroboh',
    select: 0
  }, {
    value: 'Baik',
    select: 1
  }, {
    value: 'Rapi',
    select: 2
  }, {
    value: 'Cukup Baik',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_2',
  type: 'radio',
  fields: 'a_radio_2',
  options: [{
    value: 'Kurang Percaya Diri',
    select: 0
  }, {
    value: 'Percaya Diri',
    select: 1
  }, {
    value: 'Tidak Percaya Diri',
    select: 2
  }, {
    value: 'Cukup Percaya Diri',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_3',
  type: 'radio',
  fields: 'a_radio_3',
  options: [{
    value: 'Cukup Seimbang',
    select: 0
  }, {
    value: 'Kadang Tidak Seimbang',
    select: 1
  }, {
    value: 'Tidak Stabil',
    select: 2
  }, {
    value: 'Seimbang',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_4',
  type: 'radio',
  fields: 'a_radio_4',
  options: [{
    value: 'Diterima',
    select: 0
  }, {
    value: 'Dicari',
    select: 1
  }, {
    value: 'Dapat Ditoleransi',
    select: 2
  }, {
    value: 'Disukai',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_5',
  type: 'radio',
  fields: 'a_radio_5',
  options: [{
    value: 'Jauh',
    select: 0
  }, {
    value: 'Hangat',
    select: 1
  }, {
    value: 'Penuh Ketegangan',
    select: 2
  }, {
    value: 'Cukup Dekat',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_6',
  type: 'radio',
  fields: 'a_radio_6',
  options: [{
    value: 'Cukup Hangat',
    select: 0
  }, {
    value: 'Intim',
    select: 1
  }, {
    value: 'Penuh KeteganganH',
    select: 2
  }, {
    value: 'Hangat',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_7',
  type: 'radio',
  fields: 'a_radio_7',
  options: [{
    value: 'Cukup Tepat',
    select: 0
  }, {
    value: 'Selalu Tepat',
    select: 1
  }, {
    value: 'Tidak pernah Tepat Waktu',
    select: 2
  }, {
    value: 'Biasanya Tepat',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_8',
  type: 'radio',
  fields: 'a_radio_8',
  options: [{
    value: 'Kadang Bertanggung Jawab',
    select: 0
  }, {
    value: 'Sangat Bertanggung Jawab',
    select: 1
  }, {
    value: 'Tidak Bertanggung Jawab',
    select: 2
  }, {
    value: 'Cukup Bertanggung Jawab',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_9',
  type: 'radio',
  fields: 'a_radio_9',
  options: [{
    value: 'Bekerja Cukup Baik dengan Orang Lain',
    select: 0
  }, {
    value: 'Suka Bekerja Sendiri',
    select: 1
  }, {
    value: 'Dicari-cari untuk Dijadikan Anggota Tim',
    select: 2
  }, {
    value: 'Bekerja dengan Baik dalam Tim',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_10',
  type: 'radio',
  fields: 'a_radio_10',
  options: [{
    value: 'Kadang Rendah Hati',
    select: 0
  }, {
    value: 'Sangat Rendah Hati',
    select: 1
  }, {
    value: 'Arogan/Sombong',
    select: 2
  }, {
    value: 'Cukup Rendah Hati',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_11',
  type: 'radio',
  fields: 'a_radio_11',
  options: [{
    value: 'Kadang Peduli',
    select: 0
  }, {
    value: 'Sangat Peduli dan Penuh Belas Kasih',
    select: 1
  }, {
    value: 'Tidak Peduli',
    select: 2
  }, {
    value: 'Cukup Peduli dan Berbelas Kasih',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_12',
  type: 'radio',
  fields: 'a_radio_12',
  options: [{
    value: 'Menjanjikan/Berpotensi',
    select: 0
  }, {
    value: 'Berkemampuan Memimpin',
    select: 1
  }, {
    value: 'Tidak Berjiwa Pemimpin',
    select: 2
  }, {
    value: 'Terkadang dapat Memimpin',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_13',
  type: 'radio',
  fields: 'a_radio_13',
  options: [{
    value: 'Kurang Kooperatif',
    select: 0
  }, {
    value: 'Mendukung Atasan',
    select: 1
  }, {
    value: 'Melawan Atasannya',
    select: 2
  }, {
    value: 'Terkadang Kooperatif',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Check Box 1064',
  type: 'checkbox',
  fields: 'b_checkbox_1',
  parent: 'recommendation'
}, {
  id: 'Check Box 1046',
  type: 'checkbox',
  fields: 'b_checkbox_2',
  parent: 'recommendation'
}, {
  id: 'Check Box 1047',
  type: 'checkbox',
  fields: 'b_checkbox_3',
  parent: 'recommendation'
}, {
  id: 'Check Box 1048',
  type: 'checkbox',
  fields: 'b_checkbox_4',
  parent: 'recommendation'
}, {
  id: 'Check Box 1049',
  type: 'checkbox',
  fields: 'b_checkbox_5',
  parent: 'recommendation'
}, {
  id: 'Check Box 1050',
  type: 'checkbox',
  fields: 'b_checkbox_6',
  parent: 'recommendation'
}, {
  id: 'Check Box 1045',
  type: 'checkbox',
  fields: 'is_free_from_tendencies',
  parent: 'recommendation'
},]
export const tipe_a_3 = [{
  id: 'sign',
  type: 'file',
  fields: 'sign_pict_url',
  parent: 'agreement_data'
}, {
  id: 'Nama_Lengkap 1020',
  type: 'text',
  fields: 'question_1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1021',
  type: 'text',
  fields: 'question_2',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1065',
  type: 'text',
  fields: 'question_3',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1066',
  type: 'text',
  fields: 'question_4',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1023',
  type: 'text',
  fields: 'b_question_1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1024',
  type: 'text',
  fields: 'c_question_1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1025',
  type: 'text',
  fields: 'recommendation_reason',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1026',
  type: 'text',
  fields: 'name-recommendation_other_person1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1027',
  type: 'text',
  fields: 'address-recommendation_other_person1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1028',
  type: 'text',
  fields: 'phone-recommendation_other_person1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1029',
  type: 'text',
  fields: 'name-recommendation_other_person2',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1031',
  type: 'text',
  fields: 'address-recommendation_other_person2',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1030',
  type: 'text',
  fields: 'phone-recommendation_other_person2',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1032',
  type: 'text',
  fields: 'name-recommendation_other_person3',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1034',
  type: 'text',
  fields: 'address-recommendation_other_person3',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1033',
  type: 'text',
  fields: 'phone-recommendation_other_person3',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1035',
  type: 'text',
  fields: 'filler_name',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1036',
  type: 'text',
  fields: 'filler_position',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1037',
  type: 'text',
  fields: 'filler_church',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1038',
  type: 'text',
  fields: 'filler_address',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1039',
  type: 'text',
  fields: 'filler_phone',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1040',
  type: 'text',
  fields: 'filler_email',
  parent: 'recommendation'
}, {
  id: 'Text Field 36',
  type: 'text',
  fields: 'sign_city',
  parent: 'recommendation'
}, {
  id: 'Text Field 37',
  type: 'text',
  fields: 'filler_name',
  parent: 'recommendation'
}, {
  id: 'Combo Box 101',
  type: 'date',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'Combo Box 102',
  type: 'month',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'Combo Box 103',
  type: 'year',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'RB_A_Secara Tertulis',
  type: 'radio',
  fields: '2_b',
  options: [{
    value: 'Sangat Baik',
    select: 0
  }, {
    value: 'Baik',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Kurang',
    select: 3
  }, {
    value: 'Tidak dapat Menilai',
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_Secara Verbal',
  type: 'radio',
  fields: '2_a',
  options: [{
    value: 'Sangat Baik',
    select: 0
  }, {
    value: 'Baik',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Kurang',
    select: 3
  }, {
    value: 'Tidak dapat Menilai',
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_Kemandirian',
  type: 'radio',
  fields: '1_d',
  options: [{
    value: 'Sangat Baik',
    select: 0
  }, {
    value: 'Baik',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Kurang',
    select: 3
  }, {
    value: 'Tidak dapat Menilai',
    
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_Kemampuan berefleksi',
  type: 'radio',
  fields: '1_c',
  options: [{
    value: 'Sangat Baik',
    select: 0
  }, {
    value: 'Baik',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Kurang',
    select: 3
  }, {
    value: 'Tidak dapat Menilai',
    
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_Ketertatikan terhadapPenelitian',
  type: 'radio',
  fields: '1_b',
  options: [{
    value: 'Sangat Baik',
    select: 0
  }, {
    value: 'Baik',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Kurang',
    select: 3
  }, {
    value: 'Tidak dapat Menilai',
    
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_Pemahaman Kreativitas',
  type: 'radio',
  fields: '1_a',
  options: [{
    value: 'Sangat Baik',
    select: 0
  }, {
    value: 'Baik',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Kurang',
    select: 3
  }, {
    value: 'Tidak dapat Menilai',
    
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_Antusias',
  type: 'radio',
  fields: 'recommendation_radio',
  options: [{
    value: 'Keberatan',
    select: 0
  }, {
    value: 'Cukup Kuat',
    select: 1
  }, {
    value: 'Kuat',
    select: 2
  }, {
    value: 'Sangat Antusias',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_1',
  type: 'radio',
  fields: 'a_radio_1',
  options: [{
    value: 'Rapi',
    select: 0
  }, {
    value: 'Ceroboh',
    select: 1
  }, {
    value: 'Baik',
    select: 2
  }, {
    value: 'Cukup Baik',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_2',
  type: 'radio',
  fields: 'a_radio_2',
  options: [{
    value: 'Cukup Percaya Diri',
    select: 0
  }, {
    value: 'Tidak Percaya Diri',
    select: 1
  }, {
    value: 'Percaya Diri',
    select: 2
  }, {
    value: 'Kurang Percaya Diri',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_3',
  type: 'radio',
  fields: 'a_radio_3',
  options: [{
    value: 'Seimbang',
    select: 0
  }, {
    value: 'Tidak Stabil',
    select: 1
  }, {
    value: 'Kadang Tidak Seimbang',
    select: 2
  }, {
    value: 'Cukup Seimbang',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_4',
  type: 'radio',
  fields: 'a_radio_4',
  options: [{
    value: 'Disukai',
    select: 0
  }, {
    value: 'Dapat Ditoleransi',
    select: 1
  }, {
    value: 'Dicari',
    select: 2
  }, {
    value: 'Diterima',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_5',
  type: 'radio',
  fields: 'a_radio_5',
  options: [{
    value: 'Cukup Dekat',
    select: 0
  }, {
    value: 'Penuh Ketegangan',
    select: 1
  }, {
    value: 'Hangat',
    select: 2
  }, {
    value: 'Jauh',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_6',
  type: 'radio',
  fields: 'a_radio_6',
  options: [{
    value: 'Hangat',
    select: 0
  }, {
    value: 'Penuh Ketegangan',
    select: 1
  }, {
    value: 'Intim',
    select: 2
  }, {
    value: 'Cukup Hangat',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_7',
  type: 'radio',
  fields: 'a_radio_7',
  options: [{
    value: 'Biasanya Tepat',
    select: 0
  }, {
    value: 'Tidak pernah Tepat Waktu',
    select: 1
  }, {
    value: 'Selalu Tepat',
    select: 2
  }, {
    value: 'Cukup Tepat',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_8',
  type: 'radio',
  fields: 'a_radio_8',
  options: [{
    value: 'Cukup Bertanggung Jawab',
    select: 0
  }, {
    value: 'Tidak Bertanggung Jawab',
    select: 1
  }, {
    value: 'Sangat Bertanggung Jawab',
    select: 2
  }, {
    value: 'Kadang Bertanggung Jawab',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_9',
  type: 'radio',
  fields: 'a_radio_9',
  options: [{
    value: 'Bekerja dengan Baik dalam Tim',
    select: 0
  }, {
    value: 'Dicari-cari untuk Dijadikan Anggota Tim',
    select: 1
  }, {
    value: 'Suka Bekerja Sendiri',
    select: 2
  }, {
    value: 'Bekerja Cukup Baik dengan Orang Lain',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_10',
  type: 'radio',
  fields: 'a_radio_10',
  options: [{
    value: 'Cukup Rendah Hati',
    select: 0
  }, {
    value: 'Arogan/Sombong',
    select: 1
  }, {
    value: 'Sangat Rendah Hati',
    select: 2
  }, {
    value: 'Kadang Rendah Hati',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_11',
  type: 'radio',
  fields: 'a_radio_11',
  options: [{
    value: 'Cukup Peduli dan Berbelas Kasih',
    select: 0
  }, {
    value: 'Tidak Peduli',
    select: 1
  }, {
    value: 'Sangat Peduli dan Penuh Belas Kasih',
    select: 2
  }, {
    value: 'Kadang Peduli',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_12',
  type: 'radio',
  fields: 'a_radio_12',
  options: [{
    value: 'Terkadang dapat Memimpin',
    select: 0
  }, {
    value: 'Tidak Berjiwa Pemimpin',
    select: 1
  }, {
    value: 'Berkemampuan Memimpin',
    select: 2
  }, {
    value: 'Menjanjikan/Berpotensi',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_13',
  type: 'radio',
  fields: 'a_radio_13',
  options: [{
    value: 'Terkadang Kooperatif',
    select: 0
  }, {
    value: 'Melawan Atasannya',
    select: 1
  }, {
    value: 'Mendukung Atasan',
    select: 2
  }, {
    value: 'Kurang Kooperatif',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'b_checkbox_1',
  type: 'checkbox',
  fields: 'b_checkbox_1',
  parent: 'recommendation'
}, {
  id: 'Check Box 1046',
  type: 'checkbox',
  fields: 'b_checkbox_2',
  parent: 'recommendation'
}, {
  id: 'Check Box 1047',
  type: 'checkbox',
  fields: 'b_checkbox_3',
  parent: 'recommendation'
}, {
  id: 'Check Box 1048',
  type: 'checkbox',
  fields: 'b_checkbox_4',
  parent: 'recommendation'
}, {
  id: 'Check Box 1049',
  type: 'checkbox',
  fields: 'b_checkbox_5',
  parent: 'recommendation'
}, {
  id: 'Check Box 1050',
  type: 'checkbox',
  fields: 'b_checkbox_6',
  parent: 'recommendation'
}, {
  id: 'Check Box 1045',
  type: 'checkbox',
  fields: 'is_free_from_tendencies',
  parent: 'recommendation'
},]
export const tipe_a_4 = [{
  id: 'sign',
  type: 'file',
  fields: 'sign_pict_url',
  parent: 'agreement_data'
}, {
  id: 'Nama_Lengkap 1019',
  type: 'text',
  fields: 'question_1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1020',
  type: 'text',
  fields: 'question_2',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1022',
  type: 'text',
  fields: 'question_3',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1024',
  type: 'text',
  fields: 'question_6',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1025',
  type: 'text',
  fields: 'b_question_1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1026',
  type: 'text',
  fields: 'c_question_1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1027',
  type: 'text',
  fields: 'recommendation_reason',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1028',
  type: 'text',
  fields: 'name-recommendation_other_person1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1029',
  type: 'text',
  fields: 'address-recommendation_other_person1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1030',
  type: 'text',
  fields: 'phone-recommendation_other_person1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1031',
  type: 'text',
  fields: 'name-recommendation_other_person2',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1033',
  type: 'text',
  fields: 'address-recommendation_other_person2',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1032',
  type: 'text',
  fields: 'phone-recommendation_other_person2',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1034',
  type: 'text',
  fields: 'name-recommendation_other_person3',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1036',
  type: 'text',
  fields: 'address-recommendation_other_person3',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1035',
  type: 'text',
  fields: 'phone-recommendation_other_person3',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1037',
  type: 'text',
  fields: 'filler_name',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1038',
  type: 'text',
  fields: 'filler_position',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1039',
  type: 'text',
  fields: 'filler_church',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1040',
  type: 'text',
  fields: 'filler_address',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1041',
  type: 'text',
  fields: 'filler_phone',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1042',
  type: 'text',
  fields: 'filler_email',
  parent: 'recommendation'
}, {
  id: 'Text Field 58',
  type: 'text',
  fields: 'sign_city',
  parent: 'recommendation'
}, {
  id: 'Text Field 59',
  type: 'text',
  fields: 'filler_name',
  parent: 'recommendation'
}, {
  id: 'Combo Box 1011',
  type: 'date',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'Combo Box 1012',
  type: 'month',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'Combo Box 1013',
  type: 'year',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'RB_A_Secara Tertulis',
  type: 'radio',
  fields: '2_b',
  options: [{
    value: 'Tidak dapat Menilai',
    select: 0
  }, {
    value: 'Kurang',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Baik',
    select: 3
  }, {
    value: 'Sangat Baik',
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_Secara Verbal',
  type: 'radio',
  fields: '2_a',
  options: [{
    value: 'Tidak dapat Menilai',
    select: 0
  }, {
    value: 'Kurang',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Baik',
    select: 3
  }, {
    value: 'Sangat Baik',
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_Kemandirian',
  type: 'radio',
  fields: '1_d',
  options: [{
    value: 'Tidak dapat Menilai',
    select: 0
  }, {
    value: 'Kurang',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Baik',
    select: 3
  }, {
    value: 'Sangat Baik',
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_Kemampuan berefleksi',
  type: 'radio',
  fields: '1_c',
  options: [{
    value: 'Tidak dapat Menilai',
    select: 0
  }, {
    value: 'Kurang',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Baik',
    select: 3
  }, {
    value: 'Sangat Baik',
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_Ketertatikan terhadapPenelitian',
  type: 'radio',
  fields: '1_b',
  options: [{
    value: 'Tidak dapat Menilai',
    select: 0
  }, {
    value: 'Kurang',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Baik',
    select: 3
  }, {
    value: 'Sangat Baik',
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_Pemahaman Kreativitas',
  type: 'radio',
  fields: '1_a',
  options: [{
    value: 'Tidak dapat Menilai',
    select: 0
  }, {
    value: 'Kurang',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Baik',
    select: 3
  }, {
    value: 'Sangat Baik',
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_Antusias',
  type: 'radio',
  fields: 'recommendation_radio',
  options: [{
    value: 'Keberatan',
    select: 0
  }, {
    value: 'Cukup Kuat',
    select: 1
  }, {
    value: 'Kuat',
    select: 2
  }, {
    value: 'Sangat Antusias',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_1',
  type: 'radio',
  fields: 'a_radio_1',
  options: [{
    value: 'Rapi',
    select: 0
  }, {
    value: 'Ceroboh',
    select: 1
  }, {
    value: 'Baik',
    select: 2
  }, {
    value: 'Cukup Baik',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_2',
  type: 'radio',
  fields: 'a_radio_2',
  options: [{
    value: 'Cukup Percaya Diri',
    select: 0
  }, {
    value: 'Tidak Percaya Diri',
    select: 1
  }, {
    value: 'Percaya Diri',
    select: 2
  }, {
    value: 'Kurang Percaya Diri',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_3',
  type: 'radio',
  fields: 'a_radio_3',
  options: [{
    value: 'Seimbang',
    select: 0
  }, {
    value: 'Tidak Stabil',
    select: 1
  }, {
    value: 'Kadang Tidak Seimbang',
    select: 2
  }, {
    value: 'Cukup Seimbang',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_4',
  type: 'radio',
  fields: 'a_radio_4',
  options: [{
    value: 'Disukai',
    select: 0
  }, {
    value: 'Dapat Ditoleransi',
    select: 1
  }, {
    value: 'Dicari',
    select: 2
  }, {
    value: 'Diterima',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_5',
  type: 'radio',
  fields: 'a_radio_5',
  options: [{
    value: 'Cukup Dekat',
    select: 0
  }, {
    value: 'Penuh Ketegangan',
    select: 1
  }, {
    value: 'Hangat',
    select: 2
  }, {
    value: 'Jauh',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_6',
  type: 'radio',
  fields: 'a_radio_6',
  options: [{
    value: 'Hangat',
    select: 0
  }, {
    value: 'Penuh Ketegangan',
    select: 1
  }, {
    value: 'Intim',
    select: 2
  }, {
    value: 'Cukup Hangat',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_7',
  type: 'radio',
  fields: 'a_radio_7',
  options: [{
    value: 'Biasanya Tepat',
    select: 0
  }, {
    value: 'Tidak pernah Tepat Waktu',
    select: 1
  }, {
    value: 'Selalu Tepat',
    select: 2
  }, {
    value: 'Cukup Tepat',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_8',
  type: 'radio',
  fields: 'a_radio_8',
  options: [{
    value: 'Cukup Bertanggung Jawab',
    select: 0
  }, {
    value: 'Tidak Bertanggung Jawab',
    select: 1
  }, {
    value: 'Sangat Bertanggung Jawab',
    select: 2
  }, {
    value: 'Kadang Bertanggung Jawab',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_9',
  type: 'radio',
  fields: 'a_radio_9',
  options: [{
    value: 'Bekerja dengan Baik dalam Tim',
    select: 0
  }, {
    value: 'Dicari-cari untuk Dijadikan Anggota Tim',
    select: 1
  }, {
    value: 'Suka Bekerja Sendiri',
    select: 2
  }, {
    value: 'Bekerja Cukup Baik dengan Orang Lain',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_10',
  type: 'radio',
  fields: 'a_radio_10',
  options: [{
    value: 'Cukup Rendah Hati',
    select: 0
  }, {
    value: 'Arogan/Sombong',
    select: 1
  }, {
    value: 'Sangat Rendah Hati',
    select: 2
  }, {
    value: 'Kadang Rendah Hati',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_11',
  type: 'radio',
  fields: 'a_radio_11',
  options: [{
    value: 'Cukup Peduli dan Berbelas Kasih',
    select: 0
  }, {
    value: 'Tidak Peduli',
    select: 1
  }, {
    value: 'Sangat Peduli dan Penuh Belas Kasih',
    select: 2
  }, {
    value: 'Kadang Peduli',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_12',
  type: 'radio',
  fields: 'a_radio_12',
  options: [{
    value: 'Terkadang dapat Memimpin',
    select: 0
  }, {
    value: 'Tidak Berjiwa Pemimpin',
    select: 1
  }, {
    value: 'Berkemampuan Memimpin',
    select: 2
  }, {
    value: 'Menjanjikan/Berpotensi',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_13',
  type: 'radio',
  fields: 'a_radio_13',
  options: [{
    value: 'Terkadang Kooperatif',
    select: 0
  }, {
    value: 'Melawan Atasannya',
    select: 1
  }, {
    value: 'Mendukung Atasan',
    select: 2
  }, {
    value: 'Kurang Kooperatif',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'b_checkbox_1',
  type: 'checkbox',
  fields: 'b_checkbox_1',
  parent: 'recommendation'
}, {
  id: 'Check Box 1048',
  type: 'checkbox',
  fields: 'b_checkbox_2',
  parent: 'recommendation'
}, {
  id: 'Check Box 1049',
  type: 'checkbox',
  fields: 'b_checkbox_3',
  parent: 'recommendation'
}, {
  id: 'Check Box 1050',
  type: 'checkbox',
  fields: 'b_checkbox_4',
  parent: 'recommendation'
}, {
  id: 'Check Box 1051',
  type: 'checkbox',
  fields: 'b_checkbox_5',
  parent: 'recommendation'
}, {
  id: 'Check Box 1052',
  type: 'checkbox',
  fields: 'b_checkbox_6',
  parent: 'recommendation'
}, {
  id: 'Check Box 1047',
  type: 'checkbox',
  fields: 'is_free_from_tendencies',
  parent: 'recommendation'
},]
export const tipe_a_mth = [{
  id: 'Button1',
  type: 'file',
  fields: 'sign_pict_url',
  parent: 'agreement_data'
}, {
  id: 'Nama_Lengkap 1017',
  type: 'text',
  fields: 'question_1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1018',
  type: 'text',
  fields: 'question_2',
  parent: 'recommendation'
}, 
 {
  id: 'Nama_Lengkap 1019',
  type: 'text',
  fields: 'question_4',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1021',
  type: 'text',
  fields: 'question_5',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1022',
  type: 'text',
  fields: 'question_6',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1086',
  type: 'text',
  fields: 'b_question_1',
  parent: 'recommendation'
},  {
  id: 'Nama_Lengkap 1087',
  type: 'text',
  fields: 'recommendation_reason',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1088',
  type: 'text',
  fields: 'filler_name',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1089',
  type: 'text',
  fields: 'filler_position',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1090',
  type: 'text',
  fields: 'filler_church',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1093',
  type: 'text',
  fields: 'filler_address',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1091',
  type: 'text',
  fields: 'filler_phone',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1092',
  type: 'text',
  fields: 'filler_email',
  parent: 'recommendation'
}, {
  id: 'Text Field 60',
  type: 'text',
  fields: 'filler_name',
  parent: 'recommendation'
}, {
  id: 'Combo Box 1012',
  type: 'date',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'Combo Box 1013',
  type: 'month',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'Text Field 61',
  type: 'year',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'Button2',
  type: 'radio',
  fields: 'recommendation_radio',
  options: [{
    value: 'Tidak Merekomendasikan',
    select: 0
  }, {
    value: 'Kurang Merekomendasikan',
    select: 1
  }, {
    value: 'Merekomendasikan',
    select: 2
  }, {
    value: 'Sangat Merekomendasikan',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button3',
  type: 'radio',
  fields: 'a_radio_3',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button4',
  type: 'radio',
  fields: 'a_radio_5',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button5',
  type: 'radio',
  fields: 'a_radio_6',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button6',
  type: 'radio',
  fields: 'a_radio_8',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button7',
  type: 'radio',
  fields: 'a_radio_12',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button8',
  type: 'radio',
  fields: 'a_radio_13',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Check Box 1067',
  type: 'checkbox',
  fields: 'b_checkbox_1',
  parent: 'recommendation'
}, {
  id: 'Check Box 1047',
  type: 'checkbox',
  fields: 'b_checkbox_2',
  parent: 'recommendation'
}, {
  id: 'Check Box 1048',
  type: 'checkbox',
  fields: 'b_checkbox_3',
  parent: 'recommendation'
}, {
  id: 'Check Box 1049',
  type: 'checkbox',
  fields: 'b_checkbox_4',
  parent: 'recommendation'
}, {
  id: 'Check Box 1050',
  type: 'checkbox',
  fields: 'b_checkbox_5',
  parent: 'recommendation'
}, {
  id: 'Check Box 1066',
  type: 'checkbox',
  fields: 'is_free_from_tendencies',
  parent: 'recommendation'
},]
export const tipe_a_sth = [{
  id: 'Button1',
  type: 'file',
  fields: 'sign_pict_url',
  parent: 'agreement_data'
}, {
  id: 'Nama_Lengkap 1018',
  type: 'text',
  fields: 'question_1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1019',
  type: 'text',
  fields: 'question_2',
  parent: 'recommendation'
}, 
 {
  id: 'Nama_Lengkap 1020',
  type: 'text',
  fields: 'question_5',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1021',
  type: 'text',
  fields: 'question_6',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1066',
  type: 'text',
  fields: 'b_question_1',
  parent: 'recommendation'
},  {
  id: 'Nama_Lengkap 1025',
  type: 'text',
  fields: 'recommendation_reason',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1037',
  type: 'text',
  fields: 'filler_name',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1038',
  type: 'text',
  fields: 'filler_position',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1039',
  type: 'text',
  fields: 'filler_church',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1040',
  type: 'text',
  fields: 'filler_address',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1041',
  type: 'text',
  fields: 'filler_phone',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1067',
  type: 'text',
  fields: 'filler_email',
  parent: 'recommendation'
}, {
  id: 'Text Field 53',
  type: 'text',
  fields: 'filler_name',
  parent: 'recommendation'
}, {
  id: 'Combo Box 1011',
  type: 'date',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'Combo Box 1012',
  type: 'month',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'Text Field 74',
  type: 'year',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'Button2',
  type: 'radio',
  fields: 'recommendation_radio',
  options: [{
    value: 'Tidak Merekomendasikan',
    select: 0
  }, {
    value: 'Kurang Merekomendasikan',
    select: 1
  }, {
    value: 'Merekomendasikan',
    select: 2
  }, {
    value: 'Sangat Merekomendasikan',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button3',
  type: 'radio',
  fields: 'a_radio_3',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button4',
  type: 'radio',
  fields: 'a_radio_5',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button5',
  type: 'radio',
  fields: 'a_radio_6',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button6',
  type: 'radio',
  fields: 'a_radio_8',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button7',
  type: 'radio',
  fields: 'a_radio_12',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button8',
  type: 'radio',
  fields: 'a_radio_13',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Check Box 1056',
  type: 'checkbox',
  fields: 'b_checkbox_1',
  parent: 'recommendation'
}, {
  id: 'Check Box 1048',
  type: 'checkbox',
  fields: 'b_checkbox_2',
  parent: 'recommendation'
}, {
  id: 'Check Box 1049',
  type: 'checkbox',
  fields: 'b_checkbox_3',
  parent: 'recommendation'
}, {
  id: 'Check Box 1050',
  type: 'checkbox',
  fields: 'b_checkbox_4',
  parent: 'recommendation'
}, {
  id: 'Check Box 1051',
  type: 'checkbox',
  fields: 'b_checkbox_5',
  parent: 'recommendation'
}, {
  id: 'Check Box 198',
  type: 'checkbox',
  fields: 'is_free_from_tendencies',
  parent: 'recommendation'
},]
export const tipe_a_mmin = [{
  id: 'Button1',
  type: 'file',
  fields: 'sign_pict_url',
  parent: 'agreement_data'
}, {
  id: 'Nama_Lengkap 1020',
  type: 'text',
  fields: 'question_1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1021',
  type: 'text',
  fields: 'question_2',
  parent: 'recommendation'
}, 
 {
  id: 'Nama_Lengkap 1022',
  type: 'text',
  fields: 'question_3',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1041',
  type: 'text',
  fields: 'question_4',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1087',
  type: 'text',
  fields: 'b_question_1',
  parent: 'recommendation'
},  {
  id: 'Nama_Lengkap 1088',
  type: 'text',
  fields: 'recommendation_reason',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1089',
  type: 'text',
  fields: 'filler_name',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1090',
  type: 'text',
  fields: 'filler_position',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1091',
  type: 'text',
  fields: 'filler_church',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1094',
  type: 'text',
  fields: 'filler_address',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1092',
  type: 'text',
  fields: 'filler_phone',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1093',
  type: 'text',
  fields: 'filler_email',
  parent: 'recommendation'
}, {
  id: 'Text Field 63',
  type: 'text',
  fields: 'filler_name',
  parent: 'recommendation'
}, {
  id: 'Combo Box 1013',
  type: 'date',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'Combo Box 1014',
  type: 'month',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'Text Field 64',
  type: 'year',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'Button2',
  type: 'radio',
  fields: 'recommendation_radio',
  options: [{
    value: 'Tidak Merekomendasikan',
    select: 0
  }, {
    value: 'Kurang Merekomendasikan',
    select: 1
  }, {
    value: 'Merekomendasikan',
    select: 2
  }, {
    value: 'Sangat Merekomendasikan',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button3',
  type: 'radio',
  fields: 'a_radio_3',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button5',
  type: 'radio',
  fields: 'a_radio_5',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button6',
  type: 'radio',
  fields: 'a_radio_6',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button7',
  type: 'radio',
  fields: 'a_radio_8',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button9',
  type: 'radio',
  fields: 'a_radio_12',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button10',
  type: 'radio',
  fields: 'a_radio_13',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Check Box 1068',
  type: 'checkbox',
  fields: 'b_checkbox_1',
  parent: 'recommendation'
}, {
  id: 'Check Box 1051',
  type: 'checkbox',
  fields: 'b_checkbox_2',
  parent: 'recommendation'
}, {
  id: 'Check Box 1052',
  type: 'checkbox',
  fields: 'b_checkbox_3',
  parent: 'recommendation'
}, {
  id: 'Check Box 1053',
  type: 'checkbox',
  fields: 'b_checkbox_4',
  parent: 'recommendation'
}, {
  id: 'Check Box 1054',
  type: 'checkbox',
  fields: 'b_checkbox_5',
  parent: 'recommendation'
}, {
  id: 'Check Box 1067',
  type: 'checkbox',
  fields: 'is_free_from_tendencies',
  parent: 'recommendation'
},]
export const tipe_a_mthu = [{
  id: 'Button1',
  type: 'file',
  fields: 'sign_pict_url',
  parent: 'agreement_data'
}, {
  id: 'Nama_Lengkap 1018',
  type: 'text',
  fields: 'question_1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1019',
  type: 'text',
  fields: 'question_2',
  parent: 'recommendation'
}, 
 {
  id: 'Nama_Lengkap 1020',
  type: 'text',
  fields: 'question_5',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1021',
  type: 'text',
  fields: 'question_6',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1066',
  type: 'text',
  fields: 'b_question_1',
  parent: 'recommendation'
},  {
  id: 'Nama_Lengkap 1025',
  type: 'text',
  fields: 'recommendation_reason',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1037',
  type: 'text',
  fields: 'filler_name',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1038',
  type: 'text',
  fields: 'filler_position',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1039',
  type: 'text',
  fields: 'filler_church',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1040',
  type: 'text',
  fields: 'filler_address',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1041',
  type: 'text',
  fields: 'filler_phone',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1067',
  type: 'text',
  fields: 'filler_email',
  parent: 'recommendation'
}, {
  id: 'Text Field 53',
  type: 'text',
  fields: 'filler_name',
  parent: 'recommendation'
}, {
  id: 'Combo Box 1011',
  type: 'date',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'Combo Box 1012',
  type: 'month',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'Text Field 74',
  type: 'year',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'Button2',
  type: 'radio',
  fields: 'recommendation_radio',
  options: [{
    value: 'Tidak Merekomendasikan',
    select: 0
  }, {
    value: 'Kurang Merekomendasikan',
    select: 1
  }, {
    value: 'Merekomendasikan',
    select: 2
  }, {
    value: 'Sangat Merekomendasikan',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button3',
  type: 'radio',
  fields: 'a_radio_3',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button5',
  type: 'radio',
  fields: 'a_radio_5',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button6',
  type: 'radio',
  fields: 'a_radio_6',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button7',
  type: 'radio',
  fields: 'a_radio_8',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button8',
  type: 'radio',
  fields: 'a_radio_12',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button9',
  type: 'radio',
  fields: 'a_radio_13',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Check Box 1056',
  type: 'checkbox',
  fields: 'b_checkbox_1',
  parent: 'recommendation'
}, {
  id: 'Check Box 1048',
  type: 'checkbox',
  fields: 'b_checkbox_2',
  parent: 'recommendation'
}, {
  id: 'Check Box 1049',
  type: 'checkbox',
  fields: 'b_checkbox_3',
  parent: 'recommendation'
}, {
  id: 'Check Box 1050',
  type: 'checkbox',
  fields: 'b_checkbox_4',
  parent: 'recommendation'
}, {
  id: 'Check Box 1051',
  type: 'checkbox',
  fields: 'b_checkbox_5',
  parent: 'recommendation'
}, {
  id: 'Check Box 198',
  type: 'checkbox',
  fields: 'is_free_from_tendencies',
  parent: 'recommendation'
},]
export const tipe_b_1 = [{
  id: 'sign',
  type: 'file',
  fields: 'sign_pict_url',
  parent: 'agreement_data'
}, {
  id: 'Nama_Lengkap 1042',
  type: 'text',
  fields: 'question_1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1045',
  type: 'text',
  fields: 'question_2',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1043',
  type: 'text',
  fields: 'question_3',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1046',
  type: 'text',
  fields: 'question_4',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1044',
  type: 'text',
  fields: 'question_5',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1047',
  type: 'text',
  fields: 'question_6',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1048',
  type: 'text',
  fields: 'b_question_1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1049',
  type: 'text',
  fields: 'c_question_1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1050',
  type: 'text',
  fields: 'recommendation_reason',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1051',
  type: 'text',
  fields: 'name-recommendation_other_person1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1057',
  type: 'text',
  fields: 'address-recommendation_other_person1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1054',
  type: 'text',
  fields: 'phone-recommendation_other_person1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1052',
  type: 'text',
  fields: 'name-recommendation_other_person2',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1058',
  type: 'text',
  fields: 'address-recommendation_other_person2',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1055',
  type: 'text',
  fields: 'phone-recommendation_other_person2',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1053',
  type: 'text',
  fields: 'name-recommendation_other_person3',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1059',
  type: 'text',
  fields: 'address-recommendation_other_person3',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1056',
  type: 'text',
  fields: 'phone-recommendation_other_person3',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1060',
  type: 'text',
  fields: 'filler_name',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1061',
  type: 'text',
  fields: 'filler_position',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1062',
  type: 'text',
  fields: 'filler_church',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1065',
  type: 'text',
  fields: 'filler_address',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1063',
  type: 'text',
  fields: 'filler_phone',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1064',
  type: 'text',
  fields: 'filler_email',
  parent: 'recommendation'
}, {
  id: 'Text Field 40',
  type: 'text',
  fields: 'sign_city',
  parent: 'recommendation'
}, {
  id: 'Text Field 41',
  type: 'text',
  fields: 'filler_name',
  parent: 'recommendation'
}, {
  id: 'Combo Box 1013',
  type: 'date',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'Combo Box 1014',
  type: 'month',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'Combo Box 1015',
  type: 'year',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'RB_A_Secara Tertulis',
  type: 'radio',
  fields: '2_b',
  options: [{
    value: 'Tidak dapat Menilai',
    select: 0
  }, {
    value: 'Kurang',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Baik',
    select: 3
  }, {
    value: 'Sangat Baik',
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_B_Secara Verbal',
  type: 'radio',
  fields: '2_a',
  options: [{
    value: 'Tidak dapat Menilai',
    select: 0
  }, {
    value: 'Kurang',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Baik',
    select: 3
  }, {
    value: 'Sangat Baik',
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_B_Kemandirian',
  type: 'radio',
  fields: '1_d',
  options: [{
    value: 'Tidak dapat Menilai',
    select: 0
  }, {
    value: 'Kurang',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Baik',
    select: 3
  }, {
    value: 'Sangat Baik',
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_B_Kemampuan berefleksi',
  type: 'radio',
  fields: '1_c',
  options: [{
    value: 'Tidak dapat Menilai',
    select: 0
  }, {
    value: 'Kurang',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Baik',
    select: 3
  }, {
    value: 'Sangat Baik',
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_B_Ketertatikan terhadapPenelitian',
  type: 'radio',
  fields: '1_b',
  options: [{
    value: 'Tidak dapat Menilai',
    select: 0
  }, {
    value: 'Kurang',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Baik',
    select: 3
  }, {
    value: 'Sangat Baik',
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_B_Pemahaman Kreativitas',
  type: 'radio',
  fields: '1_a',
  options: [{
    value: 'Tidak dapat Menilai',
    select: 0
  }, {
    value: 'Kurang',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Baik',
    select: 3
  }, {
    value: 'Sangat Baik',
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_B_Antusias',
  type: 'radio',
  fields: 'recommendation_radio',
  options: [{
    value: 'Keberatan',
    select: 0
  }, {
    value: 'Cukup Kuat',
    select: 1
  }, {
    value: 'Kuat',
    select: 2
  }, {
    value: 'Sangat Antusias',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_B_1',
  type: 'radio',
  fields: 'a_radio_1',
  options: [{
    value: 'Rapi',
    select: 0
  }, {
    value: 'Ceroboh',
    select: 1
  }, {
    value: 'Baik',
    select: 2
  }, {
    value: 'Cukup Baik',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_B_2',
  type: 'radio',
  fields: 'a_radio_2',
  options: [{
    value: 'Cukup Percaya Diri',
    select: 0
  }, {
    value: 'Tidak Percaya Diri',
    select: 1
  }, {
    value: 'Percaya Diri',
    select: 2
  }, {
    value: 'Kurang Percaya Diri',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_B_3',
  type: 'radio',
  fields: 'a_radio_3',
  options: [{
    value: 'Seimbang',
    select: 0
  }, {
    value: 'Tidak Stabil',
    select: 1
  }, {
    value: 'Kadang Tidak Seimbang',
    select: 2
  }, {
    value: 'Cukup Seimbang',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_B_4',
  type: 'radio',
  fields: 'a_radio_4',
  options: [{
    value: 'Disukai',
    select: 0
  }, {
    value: 'Dapat Ditoleransi',
    select: 1
  }, {
    value: 'Dicari',
    select: 2
  }, {
    value: 'Diterima',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_B_5',
  type: 'radio',
  fields: 'a_radio_5',
  options: [{
    value: 'Cukup Dekat',
    select: 0
  }, {
    value: 'Penuh Ketegangan',
    select: 1
  }, {
    value: 'Hangat',
    select: 2
  }, {
    value: 'Jauh',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_B_6',
  type: 'radio',
  fields: 'a_radio_6',
  options: [{
    value: 'Hangat',
    select: 0
  }, {
    value: 'Penuh Ketegangan',
    select: 1
  }, {
    value: 'Intim',
    select: 2
  }, {
    value: 'Cukup Hangat',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_B_7',
  type: 'radio',
  fields: 'a_radio_7',
  options: [{
    value: 'Biasanya Tepat',
    select: 0
  }, {
    value: 'Tidak pernah Tepat Waktu',
    select: 1
  }, {
    value: 'Selalu Tepat',
    select: 2
  }, {
    value: 'Cukup Tepat',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_B_8',
  type: 'radio',
  fields: 'a_radio_8',
  options: [{
    value: 'Cukup Bertanggung Jawab',
    select: 0
  }, {
    value: 'Tidak Bertanggung Jawab',
    select: 1
  }, {
    value: 'Sangat Bertanggung Jawab',
    select: 2
  }, {
    value: 'Kadang Bertanggung Jawab',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_B_9',
  type: 'radio',
  fields: 'a_radio_9',
  options: [{
    value: 'Bekerja dengan Baik dalam Tim',
    select: 0
  }, {
    value: 'Dicari-cari untuk Dijadikan Anggota Tim',
    select: 1
  }, {
    value: 'Suka Bekerja Sendiri',
    select: 2
  }, {
    value: 'Bekerja Cukup Baik dengan Orang Lain',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_B_10',
  type: 'radio',
  fields: 'a_radio_10',
  options: [{
    value: 'Cukup Rendah Hati',
    select: 0
  }, {
    value: 'Arogan/Sombong',
    select: 1
  }, {
    value: 'Sangat Rendah Hati',
    select: 2
  }, {
    value: 'Kadang Rendah Hati',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_B_11',
  type: 'radio',
  fields: 'a_radio_11',
  options: [{
    value: 'Cukup Peduli dan Berbelas Kasih',
    select: 0
  }, {
    value: 'Tidak Peduli',
    select: 1
  }, {
    value: 'Sangat Peduli dan Penuh Belas Kasih',
    select: 2
  }, {
    value: 'Kadang Peduli',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_B_12',
  type: 'radio',
  fields: 'a_radio_12',
  options: [{
    value: 'Terkadang dapat Memimpin',
    select: 0
  }, {
    value: 'Tidak Berjiwa Pemimpin',
    select: 1
  }, {
    value: 'Berkemampuan Memimpin',
    select: 2
  }, {
    value: 'Menjanjikan/Berpotensi',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'RB_B_13',
  type: 'radio',
  fields: 'a_radio_13',
  options: [{
    value: 'Terkadang Kooperatif',
    select: 0
  }, {
    value: 'Melawan Atasannya',
    select: 1
  }, {
    value: 'Mendukung Atasan',
    select: 2
  }, {
    value: 'Kurang Kooperatif',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'b_checkbox_1',
  type: 'checkbox',
  fields: 'b_checkbox_1',
  parent: 'recommendation'
}, {
  id: 'Check Box 1053',
  type: 'checkbox',
  fields: 'b_checkbox_2',
  parent: 'recommendation'
}, {
  id: 'Check Box 1054',
  type: 'checkbox',
  fields: 'b_checkbox_3',
  parent: 'recommendation'
}, {
  id: 'Check Box 1055',
  type: 'checkbox',
  fields: 'b_checkbox_4',
  parent: 'recommendation'
}, {
  id: 'Check Box 1056',
  type: 'checkbox',
  fields: 'b_checkbox_5',
  parent: 'recommendation'
}, {
  id: 'Check Box 1057',
  type: 'checkbox',
  fields: 'b_checkbox_6',
  parent: 'recommendation'
}, {
  id: 'Check Box 1052',
  type: 'checkbox',
  fields: 'is_free_from_tendencies',
  parent: 'recommendation'
},]
export const tipe_b_2 = [{
  id: 'sign',
  type: 'file',
  fields: 'sign_pict_url',
  parent: 'agreement_data'
}, {
  id: 'Nama_Lengkap 1041',
  type: 'text',
  fields: 'question_1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1044',
  type: 'text',
  fields: 'question_2',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1042',
  type: 'text',
  fields: 'question_3',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1045',
  type: 'text',
  fields: 'question_4',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1043',
  type: 'text',
  fields: 'question_5',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1046',
  type: 'text',
  fields: 'question_6',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1047',
  type: 'text',
  fields: 'b_question_1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1048',
  type: 'text',
  fields: 'c_question_1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1049',
  type: 'text',
  fields: 'recommendation_reason',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1050',
  type: 'text',
  fields: 'name-recommendation_other_person1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1056',
  type: 'text',
  fields: 'address-recommendation_other_person1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1053',
  type: 'text',
  fields: 'phone-recommendation_other_person1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1051',
  type: 'text',
  fields: 'name-recommendation_other_person2',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1057',
  type: 'text',
  fields: 'address-recommendation_other_person2',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1054',
  type: 'text',
  fields: 'phone-recommendation_other_person2',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1052',
  type: 'text',
  fields: 'name-recommendation_other_person3',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1058',
  type: 'text',
  fields: 'address-recommendation_other_person3',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1055',
  type: 'text',
  fields: 'phone-recommendation_other_person3',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1059',
  type: 'text',
  fields: 'filler_name',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1060',
  type: 'text',
  fields: 'filler_position',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1061',
  type: 'text',
  fields: 'filler_church',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1064',
  type: 'text',
  fields: 'filler_address',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1062',
  type: 'text',
  fields: 'filler_phone',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1063',
  type: 'text',
  fields: 'filler_email',
  parent: 'recommendation'
}, {
  id: 'Text Field 38',
  type: 'text',
  fields: 'sign_city',
  parent: 'recommendation'
}, {
  id: 'Text Field 39',
  type: 'text',
  fields: 'filler_name',
  parent: 'recommendation'
}, {
  id: 'Combo Box 104',
  type: 'date',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'Combo Box 105',
  type: 'month',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'Combo Box 106',
  type: 'year',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'RB_A_Secara Tertulis',
  type: 'radio',
  fields: '2_b',
  options: [{
    value: 'Sangat Baik',
    select: 0
  }, {
    value: 'Baik',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Kurang',
    select: 3
  }, {
    value: 'Tidak dapat Menilai', 
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_Secara Verbal',
  type: 'radio',
  fields: '2_a',
  options: [{
    value: 'Sangat Baik',
    select: 0
  }, {
    value: 'Baik',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Kurang',
    select: 3
  }, {
    value: 'Tidak dapat Menilai', 
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_Kemandirian',
  type: 'radio',
  fields: '1_d',
  options: [{
    value: 'Sangat Baik',
    select: 0
  }, {
    value: 'Baik',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Kurang',
    select: 3
  }, {
    value: 'Tidak dapat Menilai', 
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_Kemampuan berefleksi',
  type: 'radio',
  fields: '1_c',
  options: [{
    value: 'Sangat Baik',
    select: 0
  }, {
    value: 'Baik',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Kurang',
    select: 3
  }, {
    value: 'Tidak dapat Menilai', 
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_Ketertatikan terhadapPenelitian',
  type: 'radio',
  fields: '1_b',
  options: [{
    value: 'Sangat Baik',
    select: 0
  }, {
    value: 'Baik',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Kurang',
    select: 3
  }, {
    value: 'Tidak dapat Menilai', 
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_A_Pemahaman Kreativitas',
  type: 'radio',
  fields: '1_a',
  options: [{
    value: 'Sangat Baik',
    select: 0
  }, {
    value: 'Baik',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Kurang',
    select: 3
  }, {
    value: 'Tidak dapat Menilai', 
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_B_Antusias',
  type: 'radio',
  fields: 'recommendation_radio',
  options: [{
    value: 'Keberatan',
    select: 3
  }, {
    value: 'Cukup Kuat',
    select: 2
  }, {
    value: 'Kuat',
    select: 1
  }, {
    value: 'Sangat Antusias',
    select: 0
  }],
  parent: 'recommendation'
}, {
  id: 'RB_B_1',
  type: 'radio',
  fields: 'a_radio_1',
  options: [{
    value: 'Rapi',
    select: 3
  }, {
    value: 'Ceroboh',
    select: 2
  }, {
    value: 'Baik',
    select: 1
  }, {
    value: 'Cukup Baik',
    select: 0
  }],
  parent: 'recommendation'
}, {
  id: 'RB_B_2',
  type: 'radio',
  fields: 'a_radio_2',
  options: [{
    value: 'Cukup Percaya Diri',
    select: 3
  }, {
    value: 'Tidak Percaya Diri',
    select: 2
  }, {
    value: 'Percaya Diri',
    select: 1
  }, {
    value: 'Kurang Percaya Diri',
    select: 0
  }],
  parent: 'recommendation'
}, {
  id: 'RB_B_3',
  type: 'radio',
  fields: 'a_radio_3',
  options: [{
    value: 'Seimbang',
    select: 3
  }, {
    value: 'Tidak Stabil',
    select: 2
  }, {
    value: 'Kadang Tidak Seimbang',
    select: 1
  }, {
    value: 'Cukup Seimbang',
    select: 0
  }],
  parent: 'recommendation'
}, {
  id: 'RB_B_4',
  type: 'radio',
  fields: 'a_radio_4',
  options: [{
    value: 'Disukai',
    select: 3
  }, {
    value: 'Dapat Ditoleransi',
    select: 2
  }, {
    value: 'Dicari',
    select: 1
  }, {
    value: 'Diterima',
    select: 0
  }],
  parent: 'recommendation'
}, {
  id: 'RB_B_5',
  type: 'radio',
  fields: 'a_radio_5',
  options: [{
    value: 'Cukup Dekat',
    select: 3
  }, {
    value: 'Penuh Ketegangan',
    select: 2
  }, {
    value: 'Hangat',
    select: 1
  }, {
    value: 'Jauh',
    select: 0
  }],
  parent: 'recommendation'
}, {
  id: 'RB_B_6',
  type: 'radio',
  fields: 'a_radio_6',
  options: [{
    value: 'Hangat',
    select: 3
  }, {
    value: 'Penuh Ketegangan',
    select: 2
  }, {
    value: 'Intim',
    select: 1
  }, {
    value: 'Cukup Hangat',
    select: 0
  }],
  parent: 'recommendation'
}, {
  id: 'RB_B_7',
  type: 'radio',
  fields: 'a_radio_7',
  options: [{
    value: 'Biasanya Tepat',
    select: 3
  }, {
    value: 'Tidak pernah Tepat Waktu',
    select: 2
  }, {
    value: 'Selalu Tepat',
    select: 1
  }, {
    value: 'Cukup Tepat',
    select: 0
  }],
  parent: 'recommendation'
}, {
  id: 'RB_B_8',
  type: 'radio',
  fields: 'a_radio_8',
  options: [{
    value: 'Cukup Bertanggung Jawab',
    select: 3
  }, {
    value: 'Tidak Bertanggung Jawab',
    select: 2
  }, {
    value: 'Sangat Bertanggung Jawab',
    select: 1
  }, {
    value: 'Kadang Bertanggung Jawab',
    select: 0
  }],
  parent: 'recommendation'
}, {
  id: 'RB_B_9',
  type: 'radio',
  fields: 'a_radio_9',
  options: [{
    value: 'Bekerja dengan Baik dalam Tim',
    select: 3
  }, {
    value: 'Dicari-cari untuk Dijadikan Anggota Tim',
    select: 2
  }, {
    value: 'Suka Bekerja Sendiri',
    select: 1
  }, {
    value: 'Bekerja Cukup Baik dengan Orang Lain',
    select: 0
  }],
  parent: 'recommendation'
}, {
  id: 'RB_B_10',
  type: 'radio',
  fields: 'a_radio_10',
  options: [{
    value: 'Cukup Rendah Hati',
    select: 3
  }, {
    value: 'Arogan/Sombong',
    select: 2
  }, {
    value: 'Sangat Rendah Hati',
    select: 1
  }, {
    value: 'Kadang Rendah Hati',
    select: 0
  }],
  parent: 'recommendation'
}, {
  id: 'RB_B_11',
  type: 'radio',
  fields: 'a_radio_11',
  options: [{
    value: 'Cukup Peduli dan Berbelas Kasih',
    select: 3
  }, {
    value: 'Tidak Peduli',
    select: 2
  }, {
    value: 'Sangat Peduli dan Penuh Belas Kasih',
    select: 1
  }, {
    value: 'Kadang Peduli',
    select: 0
  }],
  parent: 'recommendation'
}, {
  id: 'RB_B_12',
  type: 'radio',
  fields: 'a_radio_12',
  options: [{
    value: 'Terkadang dapat Memimpin',
    select: 3
  }, {
    value: 'Tidak Berjiwa Pemimpin',
    select: 2
  }, {
    value: 'Berkemampuan Memimpin',
    select: 1
  }, {
    value: 'Menjanjikan/Berpotensi',
    select: 0
  }],
  parent: 'recommendation'
}, {
  id: 'RB_B_13',
  type: 'radio',
  fields: 'a_radio_13',
  options: [{
    value: 'Terkadang Kooperatif',
    select: 3
  }, {
    value: 'Melawan Atasannya',
    select: 2
  }, {
    value: 'Mendukung Atasan',
    select: 1
  }, {
    value: 'Kurang Kooperatif',
    select: 0
  }],
  parent: 'recommendation'
}, {
  id: 'Check Box 1051',
  type: 'checkbox',
  fields: 'is_free_from_tendencies',
  parent: 'recommendation'
}, {
  id: 'Check Box 1065',
  type: 'checkbox',
  fields: 'b_checkbox_1',
  parent: 'recommendation'
}, {
  id: 'Check Box 1052',
  type: 'checkbox',
  fields: 'b_checkbox_2',
  parent: 'recommendation'
}, {
  id: 'Check Box 1053',
  type: 'checkbox',
  fields: 'b_checkbox_3',
  parent: 'recommendation'
}, {
  id: 'Check Box 1054',
  type: 'checkbox',
  fields: 'b_checkbox_4',
  parent: 'recommendation'
}, {
  id: 'Check Box 1055',
  type: 'checkbox',
  fields: 'b_checkbox_5',
  parent: 'recommendation'
}, {
  id: 'Check Box 1056',
  type: 'checkbox',
  fields: 'b_checkbox_6',
  parent: 'recommendation'
}]
export const tipe_b_mth = [{
  id: 'Button1',
  type: 'file',
  fields: 'sign_pict_url',
  parent: 'agreement_data'
}, {
  id: 'Nama_Lengkap 1020',
  type: 'text',
  fields: 'question_1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1024',
  type: 'text',
  fields: 'question_2',
  parent: 'recommendation'
}, 
 {
  id: 'Nama_Lengkap 1025',
  type: 'text',
  fields: 'question_4',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1023',
  type: 'text',
  fields: 'question_5',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1026',
  type: 'text',
  fields: 'question_6',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1094',
  type: 'text',
  fields: 'b_question_1',
  parent: 'recommendation'
},  {
  id: 'Nama_Lengkap 1095',
  type: 'text',
  fields: 'recommendation_reason',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1096',
  type: 'text',
  fields: 'filler_name',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1097',
  type: 'text',
  fields: 'filler_position',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1098',
  type: 'text',
  fields: 'filler_church',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 10101',
  type: 'text',
  fields: 'filler_address',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1099',
  type: 'text',
  fields: 'filler_phone',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 10100',
  type: 'text',
  fields: 'filler_email',
  parent: 'recommendation'
}, {
  id: 'Text Field 62',
  type: 'text',
  fields: 'filler_name',
  parent: 'recommendation'
}, {
  id: 'Combo Box 1014',
  type: 'date',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'Combo Box 1015',
  type: 'month',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'Text Field 63',
  type: 'year',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'Button2',
  type: 'radio',
  fields: 'recommendation_radio',
  options: [{
    value: 'Tidak Merekomendasikan',
    select: 0
  }, {
    value: 'Kurang Merekomendasikan',
    select: 1
  }, {
    value: 'Merekomendasikan',
    select: 2
  }, {
    value: 'Sangat Merekomendasikan',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button3',
  type: 'radio',
  fields: 'a_radio_3',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button4',
  type: 'radio',
  fields: 'a_radio_5',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button6',
  type: 'radio',
  fields: 'a_radio_6',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button7',
  type: 'radio',
  fields: 'a_radio_8',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button8',
  type: 'radio',
  fields: 'a_radio_12',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button9',
  type: 'radio',
  fields: 'a_radio_13',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Check Box 1069',
  type: 'checkbox',
  fields: 'b_checkbox_1',
  parent: 'recommendation'
}, {
  id: 'Check Box 1064',
  type: 'checkbox',
  fields: 'b_checkbox_2',
  parent: 'recommendation'
}, {
  id: 'Check Box 1070',
  type: 'checkbox',
  fields: 'b_checkbox_3',
  parent: 'recommendation'
}, {
  id: 'Check Box 1071',
  type: 'checkbox',
  fields: 'b_checkbox_4',
  parent: 'recommendation'
}, {
  id: 'Check Box 1072',
  type: 'checkbox',
  fields: 'b_checkbox_5',
  parent: 'recommendation'
}, {
  id: 'Check Box 1068',
  type: 'checkbox',
  fields: 'is_free_from_tendencies',
  parent: 'recommendation'
},]
export const tipe_b_sth = [{
  id: 'Button1',
  type: 'file',
  fields: 'sign_pict_url',
  parent: 'agreement_data'
}, {
  id: 'Nama_Lengkap 1022',
  type: 'text',
  fields: 'question_1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1023',
  type: 'text',
  fields: 'question_2',
  parent: 'recommendation'
}, 
 {
  id: 'Nama_Lengkap 1024',
  type: 'text',
  fields: 'question_5',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1026',
  type: 'text',
  fields: 'question_6',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1068',
  type: 'text',
  fields: 'b_question_1',
  parent: 'recommendation'
},  {
  id: 'Nama_Lengkap 1027',
  type: 'text',
  fields: 'recommendation_reason',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1042',
  type: 'text',
  fields: 'filler_name',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1043',
  type: 'text',
  fields: 'filler_position',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1044',
  type: 'text',
  fields: 'filler_church',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1046',
  type: 'text',
  fields: 'filler_address',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1045',
  type: 'text',
  fields: 'filler_phone',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1069',
  type: 'text',
  fields: 'filler_email',
  parent: 'recommendation'
}, {
  id: 'Text Field 54',
  type: 'text',
  fields: 'filler_name',
  parent: 'recommendation'
}, {
  id: 'Combo Box 1013',
  type: 'date',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'Combo Box 1014',
  type: 'month',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'Text Field 77',
  type: 'year',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'Button2',
  type: 'radio',
  fields: 'recommendation_radio',
  options: [{
    value: 'Tidak Merekomendasikan',
    select: 0
  }, {
    value: 'Kurang Merekomendasikan',
    select: 1
  }, {
    value: 'Merekomendasikan',
    select: 2
  }, {
    value: 'Sangat Merekomendasikan',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button3',
  type: 'radio',
  fields: 'a_radio_3',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button4',
  type: 'radio',
  fields: 'a_radio_5',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button5',
  type: 'radio',
  fields: 'a_radio_6',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button6',
  type: 'radio',
  fields: 'a_radio_8',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button7',
  type: 'radio',
  fields: 'a_radio_12',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button8',
  type: 'radio',
  fields: 'a_radio_13',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Check Box 1057',
  type: 'checkbox',
  fields: 'b_checkbox_1',
  parent: 'recommendation'
}, {
  id: 'Check Box 1052',
  type: 'checkbox',
  fields: 'b_checkbox_2',
  parent: 'recommendation'
}, {
  id: 'Check Box 1053',
  type: 'checkbox',
  fields: 'b_checkbox_3',
  parent: 'recommendation'
}, {
  id: 'Check Box 1054',
  type: 'checkbox',
  fields: 'b_checkbox_4',
  parent: 'recommendation'
}, {
  id: 'Check Box 1055',
  type: 'checkbox',
  fields: 'b_checkbox_5',
  parent: 'recommendation'
}, {
  id: 'Button9',
  type: 'checkbox',
  fields: 'is_free_from_tendencies',
  parent: 'recommendation'
},]

export const tipe_c_1 = [{
  id: 'sign',
  type: 'file',
  fields: 'sign_pict_url',
  parent: 'agreement_data'
}, {
  id: 'Nama_Lengkap 1065',
  type: 'text',
  fields: 'question_1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1068',
  type: 'text',
  fields: 'question_2',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1066',
  type: 'text',
  fields: 'question_3',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1067',
  type: 'text',
  fields: 'question_4',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1069',
  type: 'text',
  fields: 'b_question_1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1071',
  type: 'text',
  fields: 'recommendation_reason',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1070',
  type: 'text',
  fields: 'name-recommendation_other_person1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1077',
  type: 'text',
  fields: 'address-recommendation_other_person1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1074',
  type: 'text',
  fields: 'phone-recommendation_other_person1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1072',
  type: 'text',
  fields: 'name-recommendation_other_person2',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1078',
  type: 'text',
  fields: 'address-recommendation_other_person2',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1075',
  type: 'text',
  fields: 'phone-recommendation_other_person2',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1073',
  type: 'text',
  fields: 'name-recommendation_other_person3',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1079',
  type: 'text',
  fields: 'address-recommendation_other_person3',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1076',
  type: 'text',
  fields: 'phone-recommendation_other_person3',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1080',
  type: 'text',
  fields: 'filler_name',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1081',
  type: 'text',
  fields: 'filler_position',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1082',
  type: 'text',
  fields: 'filler_church',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1085',
  type: 'text',
  fields: 'filler_address',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1083',
  type: 'text',
  fields: 'filler_phone',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1084',
  type: 'text',
  fields: 'filler_email',
  parent: 'recommendation'
}, {
  id: 'Text Field 42',
  type: 'text',
  fields: 'sign_city',
  parent: 'recommendation'
}, {
  id: 'Text Field 43',
  type: 'text',
  fields: 'filler_name',
  parent: 'recommendation'
}, {
  id: 'Combo Box 107',
  type: 'date',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'Combo Box 108',
  type: 'month',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'Combo Box 109',
  type: 'year',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'RB_A_Secara Tertulis',
  type: 'radio',
  fields: '2_b',
  options: [{
    value: 'Sangat Baik',
    select: 0
  }, {
    value: 'Baik',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Kurang',
    select: 3
  }, {
    value: 'Tidak dapat Menilai', 
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_C_Secara Verbal',
  type: 'radio',
  fields: '2_a',
  options: [{
    value: 'Sangat Baik',
    select: 0
  }, {
    value: 'Baik',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Kurang',
    select: 3
  }, {
    value: 'Tidak dapat Menilai', 
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_C_Kemandirian',
  type: 'radio',
  fields: '1_d',
  options: [{
    value: 'Sangat Baik',
    select: 0
  }, {
    value: 'Baik',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Kurang',
    select: 3
  }, {
    value: 'Tidak dapat Menilai', 
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_C_Kemampuan berefleksi',
  type: 'radio',
  fields: '1_c',
  options: [{
    value: 'Sangat Baik',
    select: 0
  }, {
    value: 'Baik',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Kurang',
    select: 3
  }, {
    value: 'Tidak dapat Menilai', 
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_C_Ketertatikan terhadapPenelitian',
  type: 'radio',
  fields: '1_b',
  options: [{
    value: 'Sangat Baik',
    select: 0
  }, {
    value: 'Baik',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Kurang',
    select: 3
  }, {
    value: 'Tidak dapat Menilai', 
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_C _Pemahaman Kreativitas',
  type: 'radio',
  fields: '1_a',
  options: [{
    value: 'Sangat Baik',
    select: 0
  }, {
    value: 'Baik',
    select: 1
  }, {
    value: 'Rata-rata',
    select: 2
  }, {
    value: 'Kurang',
    select: 3
  }, {
    value: 'Tidak dapat Menilai', 
    select: 4
  }],
  parent: 'recommendation'
}, {
  id: 'RB_C_Antusias',
  type: 'radio',
  fields: 'recommendation_radio',
  options: [{
    value: 'Keberatan',
    select: 3
  }, {
    value: 'Cukup Kuat',
    select: 2
  }, {
    value: 'Kuat',
    select: 1
  }, {
    value: 'Sangat Antusias',
    select: 0
  }],
  parent: 'recommendation'
}, {
  id: 'RB_C_1',
  type: 'radio',
  fields: 'a_radio_1',
  options: [{
    value: 'Rapi',
    select: 3
  }, {
    value: 'Ceroboh',
    select: 2
  }, {
    value: 'Baik',
    select: 1
  }, {
    value: 'Cukup Baik',
    select: 0
  }],
  parent: 'recommendation'
}, {
  id: 'RB_C_2',
  type: 'radio',
  fields: 'a_radio_2',
  options: [{
    value: 'Cukup Percaya Diri',
    select: 3
  }, {
    value: 'Tidak Percaya Diri',
    select: 2
  }, {
    value: 'Percaya Diri',
    select: 1
  }, {
    value: 'Kurang Percaya Diri',
    select: 0
  }],
  parent: 'recommendation'
}, {
  id: 'RB_C_3',
  type: 'radio',
  fields: 'a_radio_3',
  options: [{
    value: 'Seimbang',
    select: 3
  }, {
    value: 'Tidak Stabil',
    select: 2
  }, {
    value: 'Kadang Tidak Seimbang',
    select: 1
  }, {
    value: 'Cukup Seimbang',
    select: 0
  }],
  parent: 'recommendation'
}, {
  id: 'RB_C_4',
  type: 'radio',
  fields: 'a_radio_4',
  options: [{
    value: 'Disukai',
    select: 3
  }, {
    value: 'Dapat Ditoleransi',
    select: 2
  }, {
    value: 'Dicari',
    select: 1
  }, {
    value: 'Diterima',
    select: 0
  }],
  parent: 'recommendation'
}, {
  id: 'RB_C_5',
  type: 'radio',
  fields: 'a_radio_5',
  options: [{
    value: 'Cukup Dekat',
    select: 3
  }, {
    value: 'Penuh Ketegangan',
    select: 2
  }, {
    value: 'Hangat',
    select: 1
  }, {
    value: 'Jauh',
    select: 0
  }],
  parent: 'recommendation'
}, {
  id: 'RB_C_6',
  type: 'radio',
  fields: 'a_radio_6',
  options: [{
    value: 'Hangat',
    select: 3
  }, {
    value: 'Penuh Ketegangan',
    select: 2
  }, {
    value: 'Intim',
    select: 1
  }, {
    value: 'Cukup Hangat',
    select: 0
  }],
  parent: 'recommendation'
}, {
  id: 'RB_C_7',
  type: 'radio',
  fields: 'a_radio_7',
  options: [{
    value: 'Biasanya Tepat',
    select: 3
  }, {
    value: 'Tidak pernah Tepat Waktu',
    select: 2
  }, {
    value: 'Selalu Tepat',
    select: 1
  }, {
    value: 'Cukup Tepat',
    select: 0
  }],
  parent: 'recommendation'
}, {
  id: 'RB_C_8',
  type: 'radio',
  fields: 'a_radio_8',
  options: [{
    value: 'Cukup Bertanggung Jawab',
    select: 3
  }, {
    value: 'Tidak Bertanggung Jawab',
    select: 2
  }, {
    value: 'Sangat Bertanggung Jawab',
    select: 1
  }, {
    value: 'Kadang Bertanggung Jawab',
    select: 0
  }],
  parent: 'recommendation'
}, {
  id: 'RB_C_9',
  type: 'radio',
  fields: 'a_radio_9',
  options: [{
    value: 'Bekerja dengan Baik dalam Tim',
    select: 3
  }, {
    value: 'Dicari-cari untuk Dijadikan Anggota Tim',
    select: 2
  }, {
    value: 'Suka Bekerja Sendiri',
    select: 1
  }, {
    value: 'Bekerja Cukup Baik dengan Orang Lain',
    select: 0
  }],
  parent: 'recommendation'
}, {
  id: 'RB_C_10',
  type: 'radio',
  fields: 'a_radio_10',
  options: [{
    value: 'Cukup Rendah Hati',
    select: 3
  }, {
    value: 'Arogan/Sombong',
    select: 2
  }, {
    value: 'Sangat Rendah Hati',
    select: 1
  }, {
    value: 'Kadang Rendah Hati',
    select: 0
  }],
  parent: 'recommendation'
}, {
  id: 'RB_C_11',
  type: 'radio',
  fields: 'a_radio_11',
  options: [{
    value: 'Cukup Peduli dan Berbelas Kasih',
    select: 3
  }, {
    value: 'Tidak Peduli',
    select: 2
  }, {
    value: 'Sangat Peduli dan Penuh Belas Kasih',
    select: 1
  }, {
    value: 'Kadang Peduli',
    select: 0
  }],
  parent: 'recommendation'
}, {
  id: 'RB_C_12',
  type: 'radio',
  fields: 'a_radio_12',
  options: [{
    value: 'Terkadang dapat Memimpin',
    select: 3
  }, {
    value: 'Tidak Berjiwa Pemimpin',
    select: 2
  }, {
    value: 'Berkemampuan Memimpin',
    select: 1
  }, {
    value: 'Menjanjikan/Berpotensi',
    select: 0
  }],
  parent: 'recommendation'
}, {
  id: 'RB_C_13',
  type: 'radio',
  fields: 'a_radio_13',
  options: [{
    value: 'Terkadang Kooperatif',
    select: 3
  }, {
    value: 'Melawan Atasannya',
    select: 2
  }, {
    value: 'Mendukung Atasan',
    select: 1
  }, {
    value: 'Kurang Kooperatif',
    select: 0
  }],
  parent: 'recommendation'
}, {
  id: 'Check Box 1057',
  type: 'checkbox',
  fields: 'is_free_from_tendencies',
  parent: 'recommendation'
}, {
  id: 'Check Box 1063',
  type: 'checkbox',
  fields: 'b_checkbox_1',
  parent: 'recommendation'
}, {
  id: 'Check Box 1058',
  type: 'checkbox',
  fields: 'b_checkbox_2',
  parent: 'recommendation'
}, {
  id: 'Check Box 1059',
  type: 'checkbox',
  fields: 'b_checkbox_3',
  parent: 'recommendation'
}, {
  id: 'Check Box 1060',
  type: 'checkbox',
  fields: 'b_checkbox_4',
  parent: 'recommendation'
}, {
  id: 'Check Box 1061',
  type: 'checkbox',
  fields: 'b_checkbox_5',
  parent: 'recommendation'
}, {
  id: 'Check Box 1062',
  type: 'checkbox',
  fields: 'b_checkbox_6',
  parent: 'recommendation'
}]
export const tipe_c_mth = [{
  id: 'Button1',
  type: 'file',
  fields: 'sign_pict_url',
  parent: 'agreement_data'
}, {
  id: 'Nama_Lengkap 1065',
  type: 'text',
  fields: 'question_1',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1068',
  type: 'text',
  fields: 'question_2',
  parent: 'recommendation'
}, 
 {
  id: 'Nama_Lengkap 1066',
  type: 'text',
  fields: 'question_3',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 1067',
  type: 'text',
  fields: 'question_4',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 10102',
  type: 'text',
  fields: 'b_question_1',
  parent: 'recommendation'
},  {
  id: 'Nama_Lengkap 10103',
  type: 'text',
  fields: 'recommendation_reason',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 10104',
  type: 'text',
  fields: 'filler_name',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 10105',
  type: 'text',
  fields: 'filler_position',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 10106',
  type: 'text',
  fields: 'filler_church',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 10109',
  type: 'text',
  fields: 'filler_address',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 10107',
  type: 'text',
  fields: 'filler_phone',
  parent: 'recommendation'
}, {
  id: 'Nama_Lengkap 10108',
  type: 'text',
  fields: 'filler_email',
  parent: 'recommendation'
}, {
  id: 'Text Field 64',
  type: 'text',
  fields: 'filler_name',
  parent: 'recommendation'
}, {
  id: 'Combo Box 1016',
  type: 'date',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'Combo Box 1017',
  type: 'month',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'Text Field 65',
  type: 'year',
  fields: 'sign_date',
  parent: 'recommendation'
}, {
  id: 'Button2',
  type: 'radio',
  fields: 'recommendation_radio',
  options: [{
    value: 'Tidak Merekomendasikan',
    select: 0
  }, {
    value: 'Kurang Merekomendasikan',
    select: 1
  }, {
    value: 'Merekomendasikan',
    select: 2
  }, {
    value: 'Sangat Merekomendasikan',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button3',
  type: 'radio',
  fields: 'a_radio_3',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button4',
  type: 'radio',
  fields: 'a_radio_5',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button5',
  type: 'radio',
  fields: 'a_radio_6',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button6',
  type: 'radio',
  fields: 'a_radio_8',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button7',
  type: 'radio',
  fields: 'a_radio_12',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Button8',
  type: 'radio',
  fields: 'a_radio_13',
  options: [{
    value: 'Sangat Tidak Setuju',
    select: 0
  }, {
    value: 'Tidak Setuju',
    select: 1
  }, {
    value: 'Setuju',
    select: 2
  }, {
    value: 'Sangat Setuju',
    select: 3
  }],
  parent: 'recommendation'
}, {
  id: 'Check Box 1074',
  type: 'checkbox',
  fields: 'b_checkbox_1',
  parent: 'recommendation'
}, {
  id: 'Check Box 1065',
  type: 'checkbox',
  fields: 'b_checkbox_2',
  parent: 'recommendation'
}, {
  id: 'Check Box 1075',
  type: 'checkbox',
  fields: 'b_checkbox_3',
  parent: 'recommendation'
}, {
  id: 'Check Box 1076',
  type: 'checkbox',
  fields: 'b_checkbox_4',
  parent: 'recommendation'
}, {
  id: 'Check Box 1077',
  type: 'checkbox',
  fields: 'b_checkbox_5',
  parent: 'recommendation'
}, {
  id: 'Check Box 1073',
  type: 'checkbox',
  fields: 'is_free_from_tendencies',
  parent: 'recommendation'
}]